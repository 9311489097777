<template>
    <TextInput
        :label="this.label"
        v-model="this.localModelValue"
        :v="this.v"
        type="date"
    />
</template>

<script>
    import TextInput from '@/components/TextInput.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
        },
        components: {            
            TextInput,
        },
        computed: {
            localModelValue: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
        },
        emits: ['update:modelValue'],
        methods: {
        }
    }
</script>
